<template>
  <div class="relax">
    <div class="container">
      <div class="relaxBox">
        <div class="time">
          <span class="min">{{ min }}</span>
          <span class="colon">:</span>
          <span class="second">{{ sec }}</span>
        </div>
        <div class="tips">
          <b>亲爱的熊猫勇士，</b>
          <p>
            恭喜你都完成了，让我们休息一会儿， <br />
            继续闯关吧～
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      timer: null, //计时器
      countTime: null, //时间
      min: null, //分
      sec: null, //秒
      path: '',
    };
  },
  methods: {
    countdown() {
      this.timer = setInterval(() => {
        if (this.countTime <= 0) {
          clearInterval(this.timer);
          this.$router.replace({
            path: this.path,
          });
        } else {
          this.countTime--;
          let min = parseInt((this.countTime / 60) % 60);
          let sec = parseInt(this.countTime % 60);
          this.min = min > 9 ? min : '0' + min;
          this.sec = sec > 9 ? sec : '0' + sec;
        }
      }, 1000);
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    if (this.$route.query.time) {
      this.countTime = this.$route.query.time / 1000;
      let min = parseInt((this.countTime / 60) % 60);
      let sec = parseInt(this.countTime % 60);
      this.min = min > 9 ? min : '0' + min;
      this.sec = sec > 9 ? sec : '0' + sec;
    }
    if (this.$route.query.path) {
      this.path = this.$route.query.path;
    }
  },
  mounted() {
    this.countdown();
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1900px) {
  .relax {
    display: table-cell;
    vertical-align: middle;
    .container {
      padding: 50px;
    }
    .relaxBox {
      width: 656px;
      height: 525px;
      background-image: url('../../assets/image/other/relax.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      position: relative;
      .time {
        position: absolute;
        top: 75px;
        left: 65px;
        .min,
        .second {
          width: 58px;
          height: 70px;
          line-height: 70px;
          display: inline-block;
          background: #0f2b4e;
          border-radius: 16px;
          font-family: 'SYHeiBold';
          font-size: 36px;
          color: #ffffff;
          text-align: center;
        }
        .colon {
          height: 70px;
          vertical-align: top;
          // line-height: 70px;
          color: #0f2b4e;
          font-size: 56px;
          display: inline-block;
          padding: 0 5px;
          // position: relative;
          // top: 5px;
        }
      }
      .tips {
        font-family: 'SYHeiBold';
        color: #ffffff;
        line-height: 32px;
        position: absolute;
        top: 200px;
        left: 105px;
        b {
          font-size: 24px;
          font-weight: normal;
        }
        p {
          font-size: 20px;
        }
      }
    }
  }
}
@media screen and (min-width: 1900px) {
  .relax {
    display: table-cell;
    vertical-align: middle;
    .container {
      padding: 65px;
    }
    .relaxBox {
      width: 853px;
      height: 683px;
      background-image: url('../../assets/image/other/relax.png');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      margin: 0 auto;
      position: relative;
      .time {
        position: absolute;
        top: 97.5px;
        left: 84.5px;
        .min,
        .second {
          width: 75px;
          height: 91px;
          line-height: 91px;
          display: inline-block;
          background: #0f2b4e;
          border-radius: 20px;
          font-family: 'SYHeiBold';
          font-size: 47px;
          color: #ffffff;
          text-align: center;
        }
        .colon {
          height: 91px;
          vertical-align: top;
          // line-height: 70px;
          color: #0f2b4e;
          font-size: 73px;
          display: inline-block;
          padding: 0 7px;
          // position: relative;
          // top: 5px;
        }
      }
      .tips {
        font-family: 'SYHeiBold';
        color: #ffffff;
        line-height: 42px;
        position: absolute;
        top: 260px;
        left: 137px;
        b {
          font-size: 31px;
          font-weight: normal;
        }
        p {
          font-size: 26px;
        }
      }
    }
  }
}
</style>
